<template>
    <div>
        <!-- Start Header Area -->
        <HeaderOnePageTwo class="d-xs-none d-sm-none d-md-flex hidden-mobile" />
        <HeaderOnePageTwoMobile class="d-xs-flex d-sm-flex d-md-none" />
        <!-- End Header Area -->

        <!-- Start Slider Area -->
        <div class="slide slide-style-2 slider-video-bg d-flex align-center justify-center overlay-fix"
            data-black-overlay="1" style="height:30vh !important;">
            <v-container>
                <v-row>
                    <v-col cols="12">
                        <h4 class="heading-title1 text-left font-weight-bold text-capitalize hidden-mobile"
                            style="font-size: 61px !important; line-height: 95px !important; letter-spacing: 2px !important;">
                            Selected Governments Agencies, Military, Defense & Police
                        </h4>
                        <h4 class="heading-title1 text-left font-weight-bold text-capitalize hidden-md-and-up"
                            style="font-size: 31px !important;">
                            Selected Governments Agencies, Military, Defense & Police
                        </h4>
                    </v-col>
                    <v-col cols="12" class="inner text-left d-flex flex-column align-start pt-0">
                        <h5 class="heading-title1 heading-subtitle text-h5 font-weight-light hidden-mobile"
                            style="line-height: 40px !important;">
                            Digital Solutions for Tomorrow's Governance
                        </h5>
                        <h5 class="heading-title1 heading-subtitle text-left font-weight-light hidden-md-and-up"
                            style="line-height: 40px !important; font-size: 20px !important;">
                            Digital Solutions for Tomorrow's Governance
                        </h5>
                    </v-col>
                </v-row>
            </v-container>

            <div class="photo-background hidden-mobile">
                <div style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; background-image: linear-gradient(to bottom, rgba(42, 42, 46, 0.50) 50%, rgb(41, 41, 44));"></div>
                <img src="https://inplatform.sibernetik.co.id/siber-web/images/command-center.jpg" alt=""
                    style="width: 100%; height: 100%;" />
            </div>
            <div class="photo-background hidden-md-and-up">
                <span>
                    <div style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; background-image: linear-gradient(to bottom, rgba(42, 42, 46, 0.50) 50%, rgb(41, 41, 44));"></div>
                    <img src="https://inplatform.sibernetik.co.id/siber-web/images/command-center.jpg" alt=""
                        style="height: 100%;" />
                </span>
            </div>
        </div>
        <!-- End of Slider -->

        <!-- Start Content -->
        <!-- <div class="rn-contact-area rn-section-gap bg_color--5" style="padding-top:0px; padding-bottom:0px">
            <div class="contact-form--1">
                <v-container>
                    <div class="section rn-blog-area rn-section-gap" id="blog">
                        <div class="section-title section-title--2 text-center mb--20">
                            <h4 class="heading-title">Banking & Financial Services</h4>
                            <h3 class="heading-title mb-5">
                                Lorem Ipsum available
                            </h3>
                            <hr color="#dd8732" width="200px;" style="margin: auto; border-top: 4px  solid #dd8732">
                            <p style="text-align: center;" class="my-8">PSrE-hub is a pioneering system that allows users to process digital signatures for<br>various type of documents safely and seamlessly which can be integrated with various Certificate Authorities.</p>
                        </div>
                        <ServicePsre/>
                    </div>
                </v-container>
            </div>
        </div>   -->
        <div class="rn-contact-area bg_color--5" style="padding-top:0px; padding-bottom:0px">
            <div class="contact-form--1">
                <v-container>
                    <div class="section rn-blog-area rn-section-gap" id="blog">
                        <div class="section-title section-title--2 text-left mb--20">
                            <h4 class="heading-title">Embracing the Digital Era</h4>
                            <h3 class="heading-title mb-5">
                                Innovations for Tomorrow's World
                            </h3>
                            <hr color="#dd8732" style="margin: auto;  margin-bottom: 20px; border-top: 4px  solid #dd8732">
                            <v-row>
                                <v-col lg="8" md="12">

                                    <p style="text-align: left; ">
                                        The dawn of the digital era has marked a pivotal moment in human history, akin to
                                        the advent of the industrial revolution. But unlike any transformation we've
                                        witnessed before, the digital revolution is reshaping our world at an unprecedented
                                        pace. Every day, new technologies emerge, pushing the boundaries of what we once
                                        deemed possible and challenging our traditional ways of thinking and operating.

                                        In this era, data has become the new currency, and connectivity, the new norm. From
                                        the vast expanse of the internet to the intricate networks of blockchain, the
                                        digital realm is vast, complex, and ever-evolving. It's a realm where information is
                                        instant, where geographical distances become irrelevant, and where a single
                                        innovation can disrupt entire industries overnight.
                                    </p>
                                    <p style="text-align: left; " class="my-8">
                                        But beyond the technologies and the tangible advancements lies the true essence of
                                        the digital era: a shift in mindset. It's an age where adaptability is crucial,
                                        where lifelong learning is not just encouraged but essential, and where
                                        collaboration transcends borders. The digital era challenges us to think bigger, to
                                        look beyond the horizon, and to envision a world where challenges are seen as
                                        opportunities for innovation.
                                    </p>
                                    <p style="text-align: left; " class="my-8">
                                        For businesses, institutions, and individuals alike, navigating this digital
                                        landscape can seem daunting. The sheer volume of information, the rapidity of
                                        change, and the need for digital literacy can be overwhelming. Yet, it's in these
                                        challenges that the greatest opportunities lie. Opportunities to innovate, to
                                        redefine industries, and to create value in novel ways.

                                        As we journey deeper into this digital age, our focus should not solely be on the
                                        technologies we adopt but on the values we uphold. Embracing inclusivity, fostering
                                        creativity, and championing sustainability will ensure that the digital future we're
                                        building is not just advanced, but also equitable and humane. It's a journey of
                                        discovery, of growth, and of endless potential. And as we move forward, we must
                                        remember that in the digital era, we're not just observers; we're active
                                        participants shaping the contours of tomorrow.
                                    </p>
                                </v-col>
                                <v-col lg="4" class="hidden-mobile">
                                    <v-img
                                        src="https://inplatform.sibernetik.co.id/siber-web/images/title-command-center.jpg"
                                        height="100%" style="padding: 10px !important" />
                                </v-col>
                            </v-row>
                        </div>
                        <!-- <ServicePsre /> -->
                    </div>
                </v-container>
            </div>
        </div>

        <div class="rn-contact-area bg_color--1" style="padding-top:0px; padding-bottom:0px">
            <div class="contact-form--1">
                <v-container>
                    <div class="section rn-blog-area rn-section-gap" id="blog">
                        <v-row>
                            <v-col lg="6" md="12" class="hidden-mobile">
                                <div class="card-slider">
                                    <div class="buttons" @click="slideLeft(challenges)" style="cursor: pointer;">
                                        <v-icon elevation="0" flat x-large>mdi-chevron-left</v-icon>
                                    </div>
                                    <transition-group name="card" tag="div" class="card-container">
                                        <v-card :class="'card-solutions-' + index" v-for="(card, index) in challenges"
                                            :key="card.id">
                                            <v-img :src="card.image" height="100%" />
                                            <div class="bg_color--1 pa-2"
                                                :style="`position: absolute; bottom: 0%; width: 100%;${index == 0 ? 'background-color:#dd8732; opacity: 0.9;padding: 20px 20px 60px 20px !important;' : 'opacity: 0.5; padding: 20px 20px 20px 20px !important;'}`">
                                                <h3 :style="`width: 100%;`">
                                                    {{ card.title }}</h3>
                                                <p style="font-size: 15px;" v-if="index == 0">
                                                    {{ card.desc }}
                                                </p>
                                            </div>
                                            <!-- <div style="color: red; font-size: 100px; position: absolute;">
                                                {{ card.id }} {{ index }}
                                            </div> -->
                                        </v-card>
                                    </transition-group>
                                    <div class="buttons" @click="slideRight(challenges)" style="cursor: pointer;">
                                        <v-icon elevation="0" flat x-large>mdi-chevron-right</v-icon>
                                    </div>
                                </div>
                                <!-- <div class="card-slider-mobile">
                                    <div class="buttons" @click="slideLeft" style="cursor: pointer;">
                                        <v-icon elevation="0" flat x-large>mdi-chevron-left</v-icon>
                                    </div>
                                    <transition-group name="card" tag="div" class="card-container-mobile">
                                        <v-card v-for="(card, index) in challenges" style="height: 600px; width: 450px; transform: translateX(0);transition: 0.3s ease;" :key="card.id">
                                            {{ index }} {{ card.id }}
                                            <v-img :src="card.image" height="100%" />
                                            <div class="bg_color--1 pa-2"
                                                :style="`position: absolute; bottom: 0%; width: 100%;${index == 0 ? 'background-color:#dd8732; opacity: 0.9;padding: 20px 20px 60px 20px !important;' : 'opacity: 0.5; padding: 20px 20px 20px 20px !important;'}`">
                                                <h3 :style="`width: 100%;`">
                                                    {{ card.title }}</h3>
                                                <p style="font-size: 15px;">
                                                    {{ card.desc }}
                                                </p>
                                            </div>
                                        </v-card>
                                    </transition-group>
                                    <div class="buttons" @click="slideRight" style="cursor: pointer;">
                                        <v-icon elevation="0" flat x-large>mdi-chevron-right</v-icon>
                                    </div>
                                </div> -->
                            </v-col>
                            <v-col>
                                <div>
                                    <h2 class="hidden-mobile">Challenges in Military, Police, and Government Agencies</h2>
                                    <h2 class="hidden-md-and-up" style="font-size: 30px;">Challenges in Military, Police,
                                        and Government Agencies</h2>
                                    <hr color="#dd8732"
                                        style="margin:auto; margin-bottom: 20px; border-top: 4px solid #dd8732">
                                    <p class="whoweare-ket">
                                        In an age marked by rapid technological advancements and evolving global dynamics,
                                        military, police, and government agencies face a unique set of challenges. These
                                        institutions, entrusted with the safety, security, and governance of nations, must
                                        constantly adapt to a landscape that's in flux. From cyber threats that transcend
                                        borders to the need for real-time intelligence in volatile regions, the demands are
                                        multifaceted. Additionally, inter-departmental coordination, efficient resource
                                        allocation, and the management of vast amounts of data further complicate their
                                        operational matrix. As these agencies grapple with their inherent responsibilities
                                        in a digital age, the integration of advanced technologies and strategic solutions
                                        becomes not just beneficial, but imperative.
                                    </p>
                                </div>
                            </v-col>
                            <!-- Mobile -->
                            <v-col sm="12" class="hidden-md-and-up mt-10" style="position: relative;">
                                <v-btn @click="slideRight(challenges)" icon
                                    style="background-color: #dd8732;cursor: pointer;top: 50%; right: 0%; position: absolute; z-index: 10;">
                                    <v-icon elevation="0" flat x-large>mdi-chevron-right</v-icon>
                                </v-btn>
                                <v-btn @click="slideLeft(challenges)" icon
                                    style="background-color: #dd8732;cursor: pointer; top: 50%; left: 0%; position: absolute; z-index: 10;">
                                    <v-icon elevation="0" flat x-large>mdi-chevron-left</v-icon>
                                </v-btn>
                                <!-- <div class="buttons" @click="slideLeft(challenges)"
                                    style="cursor: pointer;left: 0%; position: absolute;">
                                    <v-icon elevation="0" flat x-large>mdi-chevron-left</v-icon>
                                </div> -->
                                <v-card
                                    :style="`width:100%; height: 500px; transform: translateX(0); transition: transition ease 0.3s; transform: scale(0.9)`">
                                    <v-btn elevation="0" icon small
                                        style="background-color: #dd8732; cursor: pointer; top: 0%; right: 0%; position: absolute; z-index: 10;">
                                        {{ challenges[0].id }}
                                    </v-btn>
                                    <v-img :src="challenges[0].image" height="100%" />
                                    <div class="bg_color--1 pa-2"
                                        :style="`width:auto; height: fit-content; text-break: normal; position: absolute; bottom: 0%; background-color:#dd8732; opacity: 0.9;padding: 20px 20px 60px 20px !important;`">
                                        <h3 :style="`width: auto;`">
                                            {{ challenges[0].title }}</h3>
                                        <p style="font-size: 15px;">
                                            {{ challenges[0].desc }}
                                        </p>
                                    </div>
                                </v-card>
                            </v-col>
                            <!-- <v-col>
                                <v-row>
                                    <v-col lg="6" md="12" sm="12" cols="12" v-for="(service, i) in challenges" :key="i">
                                        <div class="single-service service__style--siber flex"
                                            v-on:mouseenter="onHoverChallenge = service.title"
                                            v-on:mouseleave="onHoverChallenge = ''"
                                            style="display: flex; justify-content: center; align-items: center; padding: 0px !important;">
                                            <v-img :src="service.icon" width="500px" height="300px" />
                                            <div class="bg_color--1 pa-2"
                                                :style="`position: absolute; bottom: 0%; width: 100%;${onHoverChallenge == service.title ? 'color: white; background-color:#dd8732; opacity: 0.9;' : 'opacity: 0.7'}`">
                                                <h4
                                                    :style="`width: 100%; ${onHoverChallenge != service.title ? 'text-align: center' : ''}`">
                                                    {{ service.title }}</h4>
                                                <v-expand-transition>
                                                    <p style="font-size: 15px;" v-if="onHoverChallenge == service.title">
                                                        {{ service.desc }}
                                                    </p>
                                                </v-expand-transition>
                                            </div>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-col> -->
                            <!-- End Single Service  -->
                        </v-row>
                    </div>
                </v-container>
            </div>
        </div>

        <div class="rn-contact-area bg_color--5" style="padding-top:0px; padding-bottom:0px">
            <div class="contact-form--1">
                <v-container>
                    <div class="section-title section-title--2 rn-section-gap" id="blog">
                        <div class="text-center mb-10">
                            <h4 class="heading-title">Emerging Trends in Digital Governance</h4>
                            <h3 class="heading-title mb-5">
                                Shaping the Future of Surveillance and Data Management
                            </h3>
                            <hr color="#dd8732" style="margin:auto; margin-bottom: 20px; border-top: 4px solid #dd8732">
                            <p class="whoweare-ket">
                                As we venture deeper into the digital age, the landscape of governance is undergoing a
                                transformative shift. New trends, driven by technological advancements and evolving global
                                challenges, are redefining how government agencies operate, communicate, and safeguard their
                                jurisdictions. These trends not only signify the rapid pace of innovation but also highlight
                                the proactive approach agencies are adopting to stay ahead of the curve. By embracing these
                                emerging trends, government entities position themselves at the forefront of digital
                                excellence, ensuring efficient, secure, and future-ready operations.
                            </p>
                        </div>
                        <v-row>
                            <v-col :lg="4" md="12" sm="12" cols="12" v-for="(service, i) in trends" :key="i"
                                class="hidden-mobile">
                                <div class="single-service service__style--siber flex"
                                    :style="`display: flex; justify-content: center; align-items: center; padding: 0px !important;`">

                                    <v-img :src="service.image" width="500px" height="500px" />
                                    <div class="bg_color--1 pa-2"
                                        :style="`position: absolute; bottom: 0%; width: 100%; height:40%; color: white; background-color:#dd8732; opacity: 0.9;`">
                                        <!-- <div class="icon" v-html="iconSvg(service.icon)"></div> -->
                                        <h4 :style="`width: 100%;`">
                                            {{ service.title }}</h4>
                                        <!-- <div class="content" style="width: 500px;"> -->
                                        <p style="font-size: 15px; color: black">
                                            {{ service.desc }}
                                        </p>
                                        <!-- </div> -->
                                    </div>
                                </div>
                            </v-col>
                            <v-col :lg="4" md="12" sm="12" cols="12" v-for="(service, i) in trends" :key="i"
                                class="hidden-md-and-up">
                                <div class="single-service service__style--siber flex"
                                    :style="`display: flex; justify-content: center; align-items: center; padding: 0px !important; transform: scale(0.8);`">

                                    <v-img :src="service.image" width="500px" height="500px" />
                                    <div class="bg_color--1 pa-2"
                                        :style="`position: absolute; bottom: 0%; width: 100%; height:40%; color: white; background-color:#dd8732; opacity: 0.9;`">
                                        <!-- <div class="icon" v-html="iconSvg(service.icon)"></div> -->
                                        <h4 :style="`width: 100%;`">
                                            {{ service.title }}</h4>
                                        <!-- <div class="content" style="width: 500px;"> -->
                                        <p style="font-size: 15px; color: black">
                                            {{ service.desc }}
                                        </p>
                                        <!-- </div> -->
                                    </div>
                                </div>
                            </v-col>
                        </v-row>
                    </div>
                </v-container>
            </div>
        </div>

        <div class="rn-contact-area rn-section-gap bg_color--1">
            <div class="portfolio-sacousel-inner pb--30">
                <v-container class="fade-in">
                    <v-row>
                        <v-col lg="12">
                            <div class="section-title section-title--2 text-center mb--20">
                                <h4 class="heading-title mb-5">
                                    Our Solution
                                </h4>
                                <h3 class="heading-title mb-5">
                                    Tackling Modern Challenges with Digital Tools
                                </h3>
                                <hr color="#dd8732" width="200px;" style="margin: auto; border-top: 4px  solid #dd8732">
                                <p style="text-align: center; font-size: 24px !important" class="my-8">In the face of
                                    evolving challenges in governance and
                                    security, the synergy between web apps and Geographic Information Systems (GIS) emerges
                                    as a
                                    pivotal solution. By merging the capabilities of these technologies, agencies can
                                    address
                                    specific concerns, from surveillance to data management, with enhanced precision and
                                    efficiency.</p>
                            </div>
                        </v-col>
                    </v-row>
                </v-container>
                <div class="card-slider hidden-mobile">
                    <div class="buttons" @click="slideLeft(solutions)" style="cursor: pointer;">
                        <v-icon elevation="0" flat x-large>mdi-chevron-left</v-icon>
                    </div>
                    <transition-group name="card" tag="div" class="card-container-solution">
                        <v-card :class="'card-solutions-solution-' + index" v-for="(card, index) in solutions"
                            :key="card.id">
                            <v-img :src="card.image" height="100%" />
                            <div class="bg_color--1 pa-2"
                                :style="`position: absolute; bottom: 0%; width: 100%;${index == 2 ? 'background-color:#dd8732; opacity: 0.9;padding: 20px 20px 60px 20px !important;' : 'opacity: 0.5; padding: 20px 20px 20px 20px !important;'}`">
                                <h3 :style="`width: 100%;${index != 2 ? 'text-align: center;' : ''}`">
                                    {{ card.title }}</h3>
                                <p style="font-size: 15px;" v-if="index == 2">
                                    {{ card.desc }}
                                </p>
                            </div>
                        </v-card>
                    </transition-group>
                    <div class="buttons" @click="slideRight(solutions)" style="cursor: pointer;">
                        <v-icon elevation="0" flat x-large>mdi-chevron-right</v-icon>
                    </div>
                </div>
                <div class="card-slider hidden-md-and-up" style="position: relative;">
                    <v-btn @click="slideRight(solutions)" icon
                        style="background-color: #dd8732;cursor: pointer;top: 50%; right: 0%; position: absolute; z-index: 10;">
                        <v-icon elevation="0" flat x-large>mdi-chevron-right</v-icon>
                    </v-btn>
                    <v-btn @click="slideLeft(solutions)" icon
                        style="background-color: #dd8732;cursor: pointer; top: 50%; left: 0%; position: absolute; z-index: 10;">
                        <v-icon elevation="0" flat x-large>mdi-chevron-left</v-icon>
                    </v-btn>
                    <!-- <div class="buttons" @click="slideLeft(challenges)"
                                    style="cursor: pointer;left: 0%; position: absolute;">
                                    <v-icon elevation="0" flat x-large>mdi-chevron-left</v-icon>
                                </div> -->
                    <v-card
                        :style="`width:100%; height: 500px; transform: translateX(0); transition: transition ease 0.3s; transform: scale(0.9)`">
                        <v-img :src="solutions[0].image" height="100%" />
                        <v-btn elevation="0" icon small
                            style="background-color: #dd8732; cursor: pointer; top: 0%; right: 0%; position: absolute; z-index: 10;">
                            {{ solutions[0].id }}
                        </v-btn>
                        <div class="bg_color--1 pa-2"
                            :style="`width:auto; height: fit-content; text-break: normal; position: absolute; bottom: 0%; background-color:#dd8732; opacity: 0.9;padding: 20px 20px 60px 20px !important;`">
                            <h3 :style="`width: auto;`">
                                {{ solutions[0].title }}</h3>
                            <p style="font-size: 15px;">
                                {{ solutions[0].desc }}
                            </p>
                        </div>
                    </v-card>
                </div>
            </div>
        </div>

        <div class="rn-contact-area rn-section-gap bg_color--5" style="padding-top:0px; padding-bottom:0px">
            <div class="contact-form--1">
                <v-container>
                    <div class="section rn-blog-area rn-section-gap" id="blog">
                        <div class="section-title section-title--2 text-left mb--20">
                            <v-container>
                                <v-row>
                                    <v-col>
                                        <h4 class="heading-title">In this journey towards a digitized and interconnected
                                            world</h4>
                                        <h3 class="heading-title mb-5">
                                            The collaboration between technology providers and government
                                            agencies is crucial.
                                        </h3>
                                        <hr color="#dd8732" style="margin: auto; border-top: 4px  solid #dd8732">
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col lg="6" md="12" sm="12">

                                        <div style="position: relative;">
                                            <v-img
                                                src="https://inplatform.sibernetik.co.id/siber-web/images/government-monitoring.jpeg"
                                                width="100%" class="my-8" height="600px" style="z-index: 0;" />
                                            <v-card
                                                style="position:absolute; width: auto;bottom:0%; z-index: 1; opacity: 0.7; padding: 10px 20px;"
                                                flat tile>
                                                <v-card-title style="word-break: normal;">
                                                    <h4 class="heading-title">Together, we are not just implementing tools
                                                    </h4>
                                                    <h3>
                                                        But building a foundation
                                                    </h3>
                                                </v-card-title>
                                                <v-card-text>
                                                    <p style="text-align: left;">
                                                        For smarter cities, safer communities, and a more connected
                                                        world. The promise of technology and the dedication of agencies
                                                        converge, heralding a brighter, more secure tomorrow for all of us.
                                                    </p>
                                                </v-card-text>
                                            </v-card>
                                        </div>
                                    </v-col>
                                    <v-col lg="6" md="12" sm="12">

                                        <p style="text-align: left; " class="my-8">
                                            Sibernetik Integra Data, with its unwavering commitment to innovation and
                                            security, stands as a beacon in this digital revolution. Our tailored solutions,
                                            designed with precision and foresight, empower agencies to harness the full
                                            potential of web apps and GIS. By facilitating real-time data access, enhancing
                                            surveillance capabilities, and fostering inter-departmental collaboration, we
                                            are helping shape a future where technology and governance work hand in hand.
                                        </p>
                                        <p style="text-align: left; " class="my-8">
                                            As we navigate the challenges of the modern era, the role of web app
                                            surveillance systems and GIS solutions in government agencies becomes
                                            increasingly paramount. The digital transformation of these sectors not only
                                            enhances operational efficiency but also ensures that agencies are equipped to
                                            address the dynamic challenges of the 21st century. From urban planning to
                                            national security, the integration of technology into governance processes is
                                            reshaping the way agencies operate and serve the public.
                                        </p>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </div>
                    </div>
                </v-container>
            </div>
        </div>
        <!-- End Content -->

        <Footer />
    </div>
</template>
  
<script>
import HeaderOnePageTwo from "../../../components/header/HeaderOnePageTwoSiber.vue";
import HeaderOnePageTwoMobile from "../../../components/header/HeaderOnePageTwoSiberMobile.vue";
import ServicePsre from "../../../components/service/ServicePsre.vue";
import Footer from "../../../components/footer/Footer.vue";
import Card from '../../../components/portfolio/card.vue';
import feather from "feather-icons";

export default {
    components: {
        HeaderOnePageTwo,
        HeaderOnePageTwoMobile,
        ServicePsre,
        Footer,
        Card,
    },
    data: () => ({
        solutions: [
            {
                id: 0,
                title: 'Enhanced Monitoring',
                image: 'https://inplatform.sibernetik.co.id/siber-web/images/enhanced-monitoring.png',
                desc: 'Web apps integrated with GIS offer unparalleled spatial insights, allowing for detailed surveillance and monitoring.',
                imageQuery: 'A magnifying glass zooming in on a specific area of a digital map.',
            },
            {
                id: 1,
                title: 'Unified Platforms',
                image: 'https://inplatform.sibernetik.co.id/siber-web/images/unified.jpg',
                desc: 'Web apps facilitate seamless communication and data sharing among different departments, ensuring a unified approach.',
                imageQuery: 'A platform interface showcasing shared data and communication channels.',
            },
            {
                id: 2,
                title: 'Smart Resource Allocation',
                image: 'https://inplatform.sibernetik.co.id/siber-web/images/smart-resource-allocations.jpg',
                desc: 'With GIS data at their fingertips, agencies can make informed decisions about where to allocate resources.',
                imageQuery: 'Icons of resources being strategically placed on a digital map based on data insights.',
            },
            {
                id: 3,
                title: 'Data Filtering & Analysis',
                image: 'https://inplatform.sibernetik.co.id/siber-web/images/filtering-and-analysis.jpeg',
                desc: 'Advanced web apps can filter, analyze, and present relevant data, turning vast information into actionable insights.',
                imageQuery: 'A dashboard displaying filtered data analytics and GIS insights.',
            },
            {
                id: 4,
                title: 'Enhanced Security Protocols',
                image: 'https://inplatform.sibernetik.co.id/siber-web/images/secure-gismap.jpg',
                desc: 'Modern web apps come with robust security features, ensuring that sensitive GIS data remains protected from potential threats.',
                imageQuery: 'A lock icon integrated with a digital map, symbolizing secure GIS data.',
            },
        ],
        challenges: [
            {
                id: 0,
                image: "https://inplatform.sibernetik.co.id/siber-web/images/real-time-goverment.jpg",
                title: "Real-time Monitorings",
                desc: "With threats becoming more dynamic, there's a pressing need for real-time monitoring of sensitive areas, borders, and critical infrastructures."
            },
            {
                id: 1,
                image: "https://inplatform.sibernetik.co.id/siber-web/images/interconnected-goverment.jpg",
                title: "Inter-departmental Coordination",
                desc: "Ensuring seamless communication and data sharing among various departments is crucial for swift decision-making and coordinated responses."
            },
            {
                id: 2,
                image: "https://inplatform.sibernetik.co.id/siber-web/images/resource-allocation.jpeg",
                title: "Resource Allocation",
                desc: "Efficiently allocating resources, be it personnel, equipment, or emergency services, based on real-time data is a persistent challenge."
            },
            {
                id: 3,
                image: "https://inplatform.sibernetik.co.id/siber-web/images/filtering.jpg",
                title: "Data Overload",
                desc: "With the influx of data from various sources, filtering relevant information and making sense of it becomes a daunting task."
            },
            {
                id: 4,
                image: "https://inplatform.sibernetik.co.id/siber-web/images/goverment-security.jpg",
                title: "Security Concerns",
                desc: "Protecting sensitive data, especially in a digital landscape, from potential cyber threats is paramount."
            },
            // Tambahkan data kartu selanjutnya
        ],
        onHoverChallenge: '',
        trends: [
            {
                title: 'Cloud-based Platforms',
                image: 'https://inplatform.sibernetik.co.id/siber-web/images/cloud-base.jpg',
                desc: 'The shift towards cloud-based web apps, ensuring data accessibility, scalability, and security.',
            },
            {
                title: 'Mobile Integration',
                image: 'https://inplatform.sibernetik.co.id/siber-web/images/mobile-integratinos.jpg',
                desc: 'The rise of mobile-compatible web apps, allowing on-the-go access to surveillance feeds and GIS data.',
            },
            {
                title: 'AI-Driven Surveillance',
                image: 'https://inplatform.sibernetik.co.id/siber-web/images/artificial-intelligence.jpg',
                desc: 'The integration of AI algorithms within web apps to predict potential threats and anomalies using GIS data.',
            },
        ]
    }),
    methods: {
        iconSvg(icon) {
            return feather.icons[icon].toSvg();
        },
        slideLeft(variable) {
            // Metode untuk menggeser kartu ke kiri (Previous)
            const lastCard = variable.pop();
            variable.unshift(lastCard);
        },
        slideRight(variable) {
            // Metode untuk menggeser kartu ke kanan (Next)
            const firstCard = variable.shift();
            variable.push(firstCard);
        },
    },
    mounted() {
        // console.log(this.challenges)
    },
    watch: {

    }
};
</script>
<style scoped>
.heading-title1 {
    color: #dd8732 !important;
    /* line-height: 60px; */
    font-family: 'Urbanist', sans-serif !important;
}

.heading-subtitle {
    color: #f7f6f1 !important;
    font-family: 'Urbanist', sans-serif !important;
}

.card-slider-mobile {
    /* grid-row: 1 / 2; */
    /* grid-column: 1 / 8; */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.card-container-mobile {
    display: grid;
    align-items: left;
    justify-content: left;
    /* grid-template-columns: repeat(5, 1fr); */
    /* Atur jumlah kolom kartu sesuai kebutuhan */
    /* grid-gap: 10px; */
    /* Jarak antar kartu */
    overflow: hidden;
    /* padding: 20px; */
}

.card-slider {
    grid-row: 1 / 2;
    grid-column: 1 / 8;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    height: 100%;
}

.card-container {
    display: grid;
    align-items: left;
    justify-content: left;
    grid-template-columns: repeat(5, 1fr);
    /* Atur jumlah kolom kartu sesuai kebutuhan */
    grid-gap: 10px;
    /* Jarak antar kartu */
    overflow: hidden;
    /* padding: 20px; */
}

.card-container-solution {
    display: grid;
    align-items: center;
    justify-content: center;
    grid-template-columns: repeat(5, 1fr);
    /* Atur jumlah kolom kartu sesuai kebutuhan */
    grid-gap: 10px;
    /* Jarak antar kartu */
    overflow: hidden;
    /* padding: 20px; */
}

.buttons {
    margin-top: 20px;
    text-align: center;
}

.buttons button {
    margin: 5px;
    padding: 10px 20px;
    /* background-color: #007bff; */
    /* color: #fff; */
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.buttons button:hover {
    /* background-color: #0056b3; */
    font-weight: 400;
}

.card-solutions-0 {
    height: 100%;
    width: 450px;
    transform: translateX(0);
    transition: transform 0.3s ease;
    left: 14%;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px !important;
}

.card-solutions-1 {
    height: auto;
    width: 450px;
    transform: translateX(0);
    transition: transform 0.3s ease;
    right: 102%;
    z-index: 9;
    transform: scale(0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px !important;
}

.card-solutions-2 {
    height: auto;
    width: 450px;
    transform: translateX(0);
    transition: transform 0.3s ease;
    right: 212%;
    z-index: 8;
    transform: scale(0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px !important;
}

.card-solutions-3 {
    height: auto;
    width: 450px;
    transform: translateX(0);
    transition: transform 0.3s ease;
    right: 322%;
    z-index: 7;
    transform: scale(0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px !important;
}

.card-solutions-4 {
    height: auto;
    width: 450px;
    transform: translateX(0);
    transition: transform 0.3s ease;
    right: 432%;
    z-index: 6;
    transform: scale(0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px !important;
}

/* .card-solutions-5 {
    height: 450px;
    width: 450px;
    transform: translateX(0);
    transition: transform 0.3s ease;
    display: none;
} */
.card-solutions-solution-0 {
    height: 450px;
    width: 450px;
    transform: translateX(0);
    transition: transform 0.3s ease;
    right: -80%;
    z-index: 8;
    transform: scale(0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px !important;
}

.card-solutions-solution-1 {
    height: 450px;
    width: 450px;
    transform: translateX(0);
    transition: transform 0.3s ease;
    right: -30%;
    z-index: 9;
    transform: scale(0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px !important;
}

.card-solutions-solution-2 {
    height: 450px;
    width: 450px;
    transform: translateX(0);
    transition: transform 0.3s ease;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px !important;
}

.card-solutions-solution-3 {
    height: 450px;
    width: 450px;
    transform: translateX(0);
    transition: transform 0.3s ease;
    left: -30%;
    z-index: 9;
    transform: scale(0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px !important;
}

.card-solutions-solution-4 {
    height: 450px;
    width: 450px;
    transform: translateX(0);
    transition: transform 0.3s ease;
    left: -80%;
    z-index: 8;
    transform: scale(0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px !important;
}
</style>
  